.offer-page {
    padding: 30px;
}

.offer-page-wrap {
    display: grid;
    grid-template-columns: 50% 50%;
}

@media (max-width: 730px) {
    .offer-page-wrap {
        display: flex;
        flex-direction: column;
    }

    .offer-page {
        padding: 0;
    }
}