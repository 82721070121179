.reviewBox {
    padding-left: 15px;
    width: 60%;
}

.reviewBar {
    display: grid;
    grid-template-columns: max-content auto;
    align-items: center;
    margin: 2px 0;
    
}

.reviewCount {
    line-height: 1rem;
    font-size: .75rem;
    letter-spacing: .025em;
    font-weight: 400;
    padding-right: 16px;
}

.reviewBarDefault {
    border-radius: 9999px;
    background-color: #e8eaed;
    height: 10px;
}

.reviewBarPer {
    background-color: #01875f;
    width: 0%;
    height: 100%;
    border-radius: inherit;
}

.css-ryrseu-MuiRating-root {
    color: #01875f !important;
}

.ratingWrap {
    display: flex;
    padding: 10px;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
}

.ratingBox {
    width: 40%;
    text-align: center;
}

.rateCount {
    font-size: 40px;
    font-weight: bold;
}

.rateCaption {
    font-size: 12px;
    font-weight: 500;
}