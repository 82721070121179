:root {
  --white: #fff;
}

body {
  font-family: 'Inter', sans-serif;
}

a {
  text-decoration: none;
  color: #000;
}

.SnackbarContent-root {
  flex-wrap: nowrap;
}

.SnackbarItem-action {
  padding-left: 0;
}