.page-details-page {
    padding: 30px;
    max-width: 750px;
    margin: 0 auto;
}

.post-details-title,
.post-details-desc {
    padding: 20px;
}

.post-d-image-wrap .MuiImageListItem-img {
    height: 100%;
    cursor: zoom-in;
}

pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

@media (max-width: 780px) {
    .page-details-page {
        padding: 15px;
    }
}