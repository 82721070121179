.logo a {
    text-decoration: none;
    font-size: 24px;
    font-weight: bold;
}

.site-logo-wrap {
    display: flex;
    align-items: center;
    gap: 12px;
}

/* .site-logo {   
    height: 50px;
    width: 50px;
} */

.site-logo-wrap img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    border-radius: 10px;
}

.site-name p {
    font-size: 15px;
    font-weight: 600;
    width: 300px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

@media (max-width: 500px) {
    .site-name p {
        width: 190px;
    }
}

@media (max-width: 475px) {
    .site-name p {
        width: 250px;
    }
}

@media (max-width: 450px) {
    .site-name p {
        width: 200px;
    }
}

@media (max-width: 400px) {
    .site-name p {
        width: 180px;
    }
}


@media (max-width: 375px) {
    .site-name p {
        width: 165px;
    }
}

@media (max-width: 360px) {
    .site-name p {
        width: 155px;
    }
}

@media (max-width: 350px) {
    .site-name p {
        width: 145px;
    }
}

@media (max-width: 340px) {
    .site-name p {
        width: 128px;
    }
}