.acc-menuitem-portal{
    background: bisque;
}
.v1-header-av{
   
}

@media (max-width: 839px) {
    .acc-menuitem {
        font-size: 15px;
        min-height: 38px;
    }

}