.post-page {
    padding: 30px;
}

.posts-wrap {
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 780px) {
    .posts-wrap {
        display: flex;
        flex-direction: column;
    }

}

@media (max-width: 690px) {
    .post-page {
        padding: 15px;
    }

    .post-page-title {
        padding-left: 0;
        padding-top: 0;
    }
}