.confirm-delete-btn {
    color: rgb(255, 255, 255);
    background-color: rgb(255, 86, 48);
}

.confirm-delete-btn:hover {
    text-decoration: none;
    background-color: rgb(183, 29, 24);
    box-shadow: rgb(255 86 48 / 24%) 0px 8px 16px 0px;
}

.cancel-delete-btn {
    border: 1px solid rgba(145, 158, 171, 0.32);
    color: inherit;
}

.cancel-delete-btn:hover {
    border-color: rgb(33, 43, 54);
    background-color: rgba(145, 158, 171, 0.08);
}