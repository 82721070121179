.page-container {
    padding-top: 80px;
    max-width: 880px;
    margin: 0 auto;
    /* background-color: #fff; */
}

.home-search-wrap {
    max-width: 500px;
    margin: 0 auto;
    margin-top: 30px;
}

.home-page-wrap {
    padding: 30px;
}

.home-search {
    padding: 12px 40px;
    background: #fff 0 0 no-repeat padding-box;
    box-shadow: 0 0 8px #e6e6e6;
    border-radius: 6px;
    text-decoration: none;
    border: none;
    width: 100%;
}

.v1-store-tabs {
    max-width: 100vw;
    width: 880px;
    padding-top: 10px;
    margin: 0 auto;
}

.v1-tab-box {
    margin: 15px;
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(2, 1fr);
}

.v1-store-page .itemCard {
    display: grid;
    gap: 1rem;
    grid-template-columns: 80px auto;
    padding: 10px;
    border-bottom: 1px solid;
    border-bottom-color: rgb(239, 243, 244);
    margin: 15px 0;
    align-items: center;
}

.v1-store-page .itemCard .details {
    display: flex;
    justify-content: space-between;
    gap: 5px;
}

.v1-store-page .itemCard .image {
    background: red;
    height: 80px;
    width: 80px;
    border-radius: 16px;
}

.v1-store-page .itemCard .details .title {
    font-size: 16px;
    font-weight: 600;
}

.v1-store-page .itemCard .details .details {
    font-size: 14px;
}

.v1-store-page .itemCard .price .actual {
    font-size: 14px;
    font-weight: bold;
}

.v1-store-page .itemCard .price .discounted {
    font-size: 12px;
    text-decoration: line-through;
}

.tabStyle {
    min-height: 35px;
    background: #fff;
    margin-right: 8px;
    border-radius: 18px;
    padding: 8px 16px;
    border: 1px solid rgba(145, 158, 171, 0.12);
}

.home-category-title {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 10px;
    margin-left: 12px;
}

.home-page-offers-view {
    display: flex;
    justify-content: flex-end;
}

.fab-share {
    right: 80px;
    bottom: 30px;
    position: fixed;
}


@media (max-width: 1050px) {
    .fab-share {
        right: 20px;
        bottom: 20px;
    }
}

@media (max-width: 920px) {
    .v1-store-tabs {
        width: 750px;
    }

}

@media (max-width: 880px) {
    .fab-share {
        right: 70px;
        bottom: 80px;
    }
}

@media (max-width: 800px) {
    .v1-store-tabs {
        width: 100%;
    }

    .v1-tab-box {
        display: flex;
        flex-direction: column;
    }

    .fab-share {
        right: 20px;
    }
}

@media (max-width: 600px) {
    .home-page-wrap {
        padding: 0;
    }

    .home-category-title {
        padding-left: 15px;
        padding-top: 15px;
    }

    .home-category-tabs {
        padding-left: 15px;
        padding-right: 15px;
    }

    .v1-tab-box {
        margin: 5px;
    }
}