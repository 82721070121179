.app-dialog-slide .MuiPaper-root {
    max-width: 500px;
    min-width: 450px;
}

@media (max-width: 839px) {

    .app-dialog-slide {
        top: auto
    }

    .app-dialog-slide .MuiPaper-root {
        width: 100%;
        height: 100%;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        margin: 0;
        max-height: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .app-dialog-slide .app-dialog-content {
        padding: 0;
    }
}