.list-item-list {
    display: flex;
    gap: 10px;
}

.list-item-images-bg {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 12px;
    width: 150px;
    height: 150px;
    cursor: pointer;
    position: relative;
    margin: 0px auto;
}

.list-item-body {
    padding: 12px 20px;
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 2px;
}

.list-item-name {
    font-size: 18px;
    font-weight: 600;
}

.list-item-unit {
    font-size: 12px;
    font-weight: 400;
    color: #000;
}

.list-item-desc {
    font-size: 14px;
    font-weight: 500;
}

.list-item-body-wrap {
    flex: 1;
    cursor: pointer;
}

.list-item-price-wrap {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    height: 32px;
}

.list-item-price {
    display: flex;
    gap: 10px;
    align-items: center;
    cursor: pointer;
}

.list-item-price-actual {
    font-size: 14px;
}

.list-item-price-discounted {
    font-size: 15px;
    font-weight: 600;
}

.sPhotoCount {
    background-color: rgba(0, 0, 0, .6);
    border-radius: 12px 0;
    bottom: 0;
    font-size: 12px;
    color: #fff;
    line-height: normal;
    padding: 4px 8px;
    position: absolute;
    right: 0;
}

.list-item-offer {
    font-size: 10px;
    background: #ff3269;
    position: absolute;
    right: 5px;
    border-radius: 20px;
    padding: 2px 5px;
    color: #fff;
    top: 5px;
}

.list-item-desc-txt {
    font-size: 12px;
    color: #a7a3a3
}

@media (max-width: 500px) {
    .list-item-images-bg {
        width: 130px;
        height: 130px;
    }

    .list-item-body {
        padding: 8px;
    }

    .list-item-name {
        font-size: 15px;
        font-weight: 500;
    }

    .list-item-desc-txt {
        display: none;
    }

}

@media (max-width: 420px) {
    .list-item-images-bg {
        width: 125px;
    }

    .list-item-list {
        gap: 4px;
    }
}

@media (max-width: 400px) {
    .list-item-price {
        gap: 8px;
    }

    .list-item-price-actual {
        font-size: 13px;
    }

    .list-item-price-discounted {
        font-size: 14px;
    }

    .list-item-desc {
        font-size: 12px;
    }
}

@media (max-width: 385px) {
    .list-item-images-bg {
        width: 125px;        
        height: 150px;
    }

    .list-item-price-wrap {
        gap: 0;
        align-items: flex-start;
        justify-content: center;
        height: 50px;
        flex-direction: column;
    }

}