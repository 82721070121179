.sUserReview {
    padding: 20px;
    position: relative;
}

.sUserReviewD {
    display: flex;
    justify-content: space-between;
    column-gap: 15px;
    align-items: center;
}

.sUserReviewAction Button {
    padding: 21px 5px;
    font-size: 20px;
    border-radius: 100%;
}

.sUserReviewName {
    font-size: 16px;
    font-weight: 500;
    flex: 1;
}

.sUserReviewImg {
    height: 60px;
    width: 60px;
    border-radius: 100%;
}

.sUserReviewImg img {
    border-radius: 100%;
}

.sUserReviewRate {
    display: flex;
    column-gap: 20px;
    margin-top: 15px;
    font-size: 14px;
    align-items: center;
}

.sUserReviewComment {
    font-size: 15px;
    margin-top: 15px;
    line-height: 20px;
}

.sOwnerReview {
    background-color: rgb(248, 249, 250);
    padding: 18px 18px;
    margin-top: 12px;
    border-radius: 16px;
    width: 90%;
    float: right;
}

.clearfix {
    clear: both;
}

.sOwnerReviewHead {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin-bottom: 16px;
}

.sOwnerReviewComment {
    font-size: 14px;
}

.sOwnerReviewHeadName {
    font-size: 16px;
    font-weight: 500;
}

.sReviewSeeAll {
    padding: 20px;
}

.sReviewSeeAll button {
    color: rgb(1, 135, 95);
    font-size: 13px;
    text-transform: inherit;
    font-weight: 600;
}

.sOwnerReviewPosted,
.sUserReviewPosted {
    font-size: 14px;
}

@media (max-width: 500px) {
    .sUserReviewName {
        font-size: 15px;
    }

    .sUserReviewComment {
        margin-top: 10px;
        font-size: 14px;
    }

    .sUserReviewD {
        column-gap: 10px;
    }
    .sOwnerReview {
        width: 99%;
        padding: 15px 15px;
    }

    .sOwnerReviewHead {
        margin-bottom: 10px;
    }

    .sOwnerReviewHead {
        flex-direction: column;
    }

    .sOwnerReviewHeadName {
        font-size: 15px;
    }

    .sOwnerReviewPosted,
    .sUserReviewPosted {
        font-size: 12px;
    }
}