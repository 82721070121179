.loading-page {
    position: fixed;
    top: 50%;
    left: 0;
    right: 0;
    transform: translateY(-50%);
    background: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    z-index: 1200;
    height: 100%;
}

.loading-page-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.qr-scan-store-name {
    text-align: center;
    color: #000;
    font-weight: 700;
}

@media (max-width: 330px) {
    .animation-scan {
        height: 250px;
    }
}

@media (max-width: 320px) {
    .animation-scan {
        height: 230px;
    }
}