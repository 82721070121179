.app-dialog .MuiPaper-root {
    max-width: 720px;
    min-width: 600px;
}

@media (max-width: 839px) {

    .app-dialog .MuiPaper-root {
        width: 100%;
        height: 100%;
        border-radius: 0;
        margin: 0;
        max-height: 100%;
        max-width: 100%;
        min-width: 100%;
    }

    .app-dialog .app-dialog-content {
        padding: 20px 10px;
    }
}