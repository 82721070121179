.v1-store-about {
    max-width: 612px;
    padding: auto;
}

.page-container-about {
    max-width: 1050px !important;
}

.about-page-wrap {
    display: flex;
    gap: 20px
}

.v1-store-rightmenu {
    width: 285px;
}

.v1-about-main-content {
    display: flex;
    flex: 1;
}

.about-page-wrap {
    display: flex;
    gap: 20px;
}

.v1-store-about-container {
    position: relative;
}

.v1-store-about-head {
    padding: 20px;
    display: flex;
    gap: 15px;
    align-items: center;
    flex-wrap: wrap;
}

.v1-store-about-head .time-wrap {
    font-size: 13px;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-wrap: wrap;
}

.v1-store-about-head .time-txt {
    color: #01875f;
}

.v1-store-about-actions ul {
    padding: 0;
    margin: 0;
    display: flex;
    gap: 25px;
    align-items: center;
    text-align: center;
    padding: 0px 20px;
}

.v1-store-about-actions ul li {
    list-style: none;
    font-size: 13px;
    font-weight: 600;
}

.v1-store-about-actions ul li .subText {
    font-size: 12px;
    padding-top: 4px;
    font-weight: 400;
}

.v1-store-about-ac-btns {
    padding: 20px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.v1-store-name {
    font-size: 28px;
    font-weight: 700;
}

.v1-store-name-wrap {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    flex: 1;
}

.v1-store-av {
    width: 90px;
    height: 90px;
    border-radius: 16px;
}

.sTitle {
    padding: 15px 15px 10px 15px;
    font-size: 16px;
    font-weight: 600;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.sTitle a {
    padding-left: 10px;
    color: #000;
}

.sAbout {
    font-size: 15px;
    line-height: 20px;
}

.sBody {
    padding: 5px 20px 20px 20px;
}

.divider {
    height: 1px;
    border-bottom: 1px solid;
    border-bottom-color: rgb(239, 243, 244);
}

.sReview {
    padding: 15px 15px 10px 15px;
    font-size: 16px;
    font-weight: 600;
}

.sReviewSub {
    font-size: 13px;
    font-weight: 400;
}

.css-dqr9h-MuiRating-label {
    font-size: 50px;
}

.css-jue3ft-MuiRating-root {
    color: #01875f !important;
}

.sWriteReview {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.sAddress {
    font-size: 14px;
    display: flex;
    gap: 10px;
    align-items: baseline;
    justify-content: space-between;
}

.SBodyText {
    font-size: 15px;
}

.reviewDialogHeader {
    display: grid;
    grid-template-columns: 60px auto;
    align-items: center;
    column-gap: 15px;
}

/* .reviewDialogImg {
    height: 60px;
    width: 60px;
}

.reviewDialogImg img {
    height: 100%;
    width: 100%;
    border-radius: 16px;
} */

.reviewDialogName {
    font-size: 16px;
    font-weight: 600;
}

.reviewStarWrap {
    width: 100vw;
    overflow-x: scroll
}

.reviewStarWrap::-webkit-scrollbar {
    height: 0px;
    width: 8px;
    border: 1px solid #fff;
}

.reviewStarFilter {
    display: flex;
    column-gap: 15px;
    margin-top: 10px;
}

.reviewStarFilter ul {
    display: flex;
    flex-direction: row;
    gap: 10px;
    padding: 10px;
}

.reviewStarFilter ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.reviewStarFilterType {
    min-width: 45px !important;
    padding: 2px 10px !important;
    font-size: 12px !important;
    border: 1px solid #ccc !important;
    color: #000 !important;
}

.reStarFilterType {
    min-width: 45px !important;
    padding: 2px 10px !important;
    font-size: 12px !important;
    border: 1px solid #ccc !important;
    color: #000 !important;
}

.reviewFilterMore {
    display: flex;
    align-items: center;
    column-gap: 0px;
    font-size: 14px;
    flex-wrap: wrap;
}

.store-rating-dialog-wrap {
    text-align: center;
    margin: 20px 10px;
}

.store-rating-dialog-wrap .MuiRating-sizeLarge label {
    /* margin-right: 15px; */
}

.fab-en-wrap {
    display: none;
}

.fab-en {
    right: 20px;
    bottom: 80px;
    position: fixed;
}


.sPhotoBody {
    display: grid;
    padding: 15px;
    border-radius: 16px;
    gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
}

.sPhotoBody img {
    width: 100%;
}

.sPhotoBody .sPhoto {
    height: 200px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 16px;
    width: 100%;
    cursor: pointer;
    position: relative;
}

.sPhotoCount {
    background-color: rgba(0, 0, 0, .6);
    border-radius: 12px 0;
    bottom: 0;
    font-size: 14px;
    color: #fff;
    line-height: normal;
    padding: 4px 8px;
    position: absolute;
    right: 0;
}

.sPhotoName {
    font-size: 13px;
    font-weight: 500;
    padding: 6px 0px;
    text-align: center;
}

.recommended-btn{
    background-color: #efefef !important;
    color: #000;
    box-shadow: none;
}

@media (max-width: 1072px) {

    .page-container-about,
    .page-header-content {
        max-width: 990px !important;
    }
}

@media (max-width: 1020px) {

    .page-container-about,
    .page-header-content {
        max-width: 900px !important;
    }
}

@media (max-width: 920px) {

    .page-container-about,
    .page-header-content {
        max-width: 850px !important;
    }
}

@media (max-width: 880px) {

    .page-container-about,
    .page-header-content {
        max-width: 700px !important;
    }

    .v1-store-rightmenu {
        display: none;
    }

    .fab-en {
        right: 100px;
    }

    .fab-en-wrap {
        display: block;
    }
}


@media (max-width: 839px) {
    .v1-store-about {
        max-width: 100%;
    }

    .v1-store-card-w {
        box-shadow: none;
    }

    .v1-store-about-actions {
        width: 100vw;
    }

    .v1-store-about-actions ::-webkit-scrollbar,
    .reviewStarWrap::-webkit-scrollbar {
        height: 0px;
        width: 8px;
        border: 1px solid #fff;
    }

    .v1-store-about-actions ul {
        overflow-x: auto;
    }

    .fab-en {
        right: 20px;
    }

}


@media (max-width: 620px) {
    .sPhotoBody {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .sAddress {
        flex-direction: column;
    }
}

@media (max-width: 500px) {
    .v1-store-name {
        font-size: 20px;
        font-weight: 600;
    }

    .v1-store-av {
        width: 70px;
        height: 70px;
    }

    .SBodyText {
        font-size: 14px;
    }
}


@media (max-width: 460px) {
    .sPhotoBody {
        grid-template-columns: repeat(1, 1fr);
    }
}

@media (max-width: 400px) {
    .v1-store-about-head {
        gap: 10px;
    }

    .v1-store-about-head .time-wrap {
        font-size: 12px;
    }

    .store-rating-dialog-wrap .MuiRating-sizeLarge label {
        /* margin-right: 2px; */
    }

}