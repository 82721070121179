.user-profile-page {
    margin-top: 100px;
}

.user-profile-wrap {
    max-width: 600px;
    margin: 0 auto;
}

.user-profile-av {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
}

.user-profile-heading {
    margin-bottom: 25px;
    font-size: 15px;
    font-weight: 600;
}

.user-profile-img-caption {
    font-size: 12px;
    font-weight: 400;
    color: rgb(99, 115, 129);
}

.user-profile-card {
    padding: 25px;
}

.user-profile-info {
    margin-top: 20px;
}

.dashed-div {
    border-width: 0px 0px thin;
    border-color: rgba(145, 158, 171, 0.24);
    border-style: dashed;
    margin: 10px 20px;
}
.user-profile-label{
    font-size: 13px;
    font-weight: 400;
}
.user-profile-field{
    font-weight: 600;
    font-size: 15px;
    margin-left: 10px;
    margin-top: 5px;
}
.user-profile-d{
    margin-top: 10px;
    margin-bottom: 10px;
}


.user-c-image-wrap {
    width: 155px;
    height: 155px;
    margin: auto;
    display: flex;
    cursor: pointer;
    overflow: hidden;
    border-radius: 50%;
    align-items: center;
    position: relative;
    justify-content: center;
    border: 1px dashed rgba(145, 158, 171, 0.32);
}

.user-c-image {
    width: 136px;
    height: 136px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: rgb(145, 158, 171);
    background-color: rgb(244, 246, 248);
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 50%;
    text-decoration: none;
}

.user-c-image-caption {
    margin: 16px auto 0px;
    line-height: 1.5;
    font-size: 0.75rem;
    font-weight: 400;
    color: rgb(99, 115, 129);
    display: block;
    text-align: center;
}

.user-close-image-ico {
    position: absolute;
    color: rgba(255, 255, 255, 0.72);
    background-color: rgba(22, 28, 36, 0.48);
    top: 10px;
    right: 28px;
}
