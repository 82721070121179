.store-social-share {
    margin-top: 15px;
    margin-bottom: 15px;
}

div[class^="makeStyles-container-"] {
    padding-top: 10px;
    padding-bottom: 10px;
}

div[class^="makeStyles-copyContainer-"] {
    color: #000;
    background: #fff;
    border: 0;
    box-shadow: 0 0 2px 0 rgb(145 158 171 / 20%), 0 12px 24px -4px rgb(145 158 171 / 12%);
}

div[class^="makeStyles-copyUrl-"] {
    color: #000;
}

.v1-store-qrTitle {
    padding: 15px;
    font-size: 15px;
    font-weight: 500;
}

.v1-store-qrwrapInner {
    margin: 0 auto;
    width: 250px;
    padding: 10px;
}

.v1-store-qrAction {
    justify-content: space-around;
    display: flex;
    margin: 5px 15px 15px 15px;
}

.v1-store-reviewTitle {
    padding: 15px;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    align-items: center;
    gap: 10px;
}

.ratingWrap {
    display: flex;
    padding: 10px;
    align-items: center;
    margin-bottom: 10px;
    flex-wrap: wrap;
}