.app-footer {
    padding: 20px;
    max-width: 880px;
    margin: 0 auto;
}

.f-link-wrap ul {
    padding: 0;
    margin: 0;
}

.f-link-wrap ul li {
    list-style: none;
}

.f-link-wrap ul li a {
    color: #fff;
    text-decoration: none;
    font-size: 14px;
}

.f-heading {
    font-weight: 700;
    color: rgba(255, 255, 255, 0.6);
}

.v1-footer-container {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    gap: 20px;
    flex-wrap: wrap;
}

@media (max-width: 700px) {
    .v1-footer-container {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
}