.login-content {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 25px;
}

.l-error {
    border: 1px solid #e50b20;
    font-size: 16px;
    line-height: 24px;
    padding: 11px 16px;
    border-radius: 4px;
    background-color: #e50b201a;
}

.verify-help-text {
    display: flex;
    flex-direction: row;
    padding-bottom: 12px;
    padding-top: 12px;
    justify-content: space-between;
}

.f-14 {
    font-size: 14px;
}

.otp-input-style {
    height: 50px;
    border-radius: 6px;
    border: 1px solid #d9d9d9;
    color: #1a181e;
    width: 50px !important;
    font-size: 32px;
    line-height: 38px;
    font-weight: 700;
    margin-right: 10px;
    padding: 11px 8px;
}

.otp-focus-style {
    border-color: #146eb4 !important;
}

.resend-link {
    color: #146eb4;
    margin-left: 3px;
}

@media (max-width: 880px) {
    .login-content {
        padding: 20px;
    }
}

@media (max-width: 400px) {
    .otp-input-style {
        width: 45px !important;
        font-size: 30px;
    }
}

@media (max-width: 360px) {
    .otp-input-style {
        width: 40px !important;
        font-size: 28px;
    }
}

@media (max-width: 320px) {
    .otp-input-style {
        width: 35px !important;
        font-size: 25px;
    }
}