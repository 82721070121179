.search-page-header {
    height: 70px;
    position: fixed;
    width: 100%;
    border-bottom: 1px solid;
    border-color: rgba(145, 158, 171, 0.24);
    z-index: 1;
    background: #fff;
}

.search-page-header-wrap {
    max-width: 660px;
    margin: 0 auto;
    height: 100%;
}

.search-page-body {
    max-width: 660px;
    margin: 0 auto;
    padding-top: 90px;
}

.search-page-body-wrap {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 30px;
}

.search-page-header-content {
    display: flex;
    height: 100%;
    align-items: center;
    margin-left: 10px;
    margin-right: 20px;
}

.search-back {
    width: 100px;
}

.search-back-btn {
    color: #000;
}

.search-page-txt-wrap {
    display: flex;
    flex: 1;
    position: relative;
}

.search-page-txt {
    padding: 10px 40px;
    background: #fff 0 0 no-repeat padding-box;
    box-shadow: 0 0 8px #e6e6e6;
    border-radius: 6px;
    text-decoration: none;
    border: none;
    width: 100%;
    font-size: 15px;
    height: 45px;
}

.search-page-txt:focus-visible {
    outline: none;
}

.search-ico {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 14px;
    left: 14px;
}

@media (max-width: 880px) {}

@media (max-width: 660px) {
    .search-back-btn {
        display: none;
    }

    .search-back {
        width: 50px;
    }
}