.app-sm-tabs {
    display: none;
}

.app-sm-tab {
    min-width: 70px;
}

.profile-dialog-list {
    min-height: 48px;
}

@media (max-width: 880px) {
    .app-sm-tabs {
        display: block;
    }
}

@media (max-width: 350px) {
    .app-sm-tab {
        min-width: 64px;
    }

}